import MedeinaVariables from '@/util/variables';
import {IExtendedTelemetryItem, ITelemetryPlugin} from '@microsoft/1ds-core-js';
import {
    IExtendedConfiguration,
    IPropertyConfiguration,
    PropertiesPlugin,
} from '@microsoft/1ds-analytics-web-js';
import {QosPlugin, IQosConfiguration} from '@microsoft/1ds-qos-js';
import redactShareToken from './redactShareToken';
import {getCurrentTenantId} from '@/util/msal/authConfig';
import {getStaticFeatureFlag} from '../hasStaticFeatureFlags';
import MedeinaFeatures from '../features';

// TODO: Integrate region-specific telemetry endpoints.
// https://eng.ms/docs/products/geneva/collect/clientingestion/onboardingsteps/endpoints/configureuploaddestination
const ENDPOINT_URLS = {
    // Public Cloud: US
    US: 'https://us-mobile.events.data.microsoft.com/OneCollector/1.0/',
    // Public Cloud: European Union
    EU: 'https://eu-mobile.events.data.microsoft.com/OneCollector/1.0/',
    // Global
    Global: undefined,
};

const enhancedTelemetry = getStaticFeatureFlag(MedeinaFeatures.EnableEnhancedTelemetry);

export function initializeTelemetry(item: IExtendedTelemetryItem) {
    item.data = item.data || {};
    // attach tenantId to telemetry data
    item.data.tenantId = getCurrentTenantId() || '';

    // adding traceId and userId to telemetry data
    item.data.traceId = item.ext?.dt?.traceId || item.ext?.trace?.traceId || '';
    item.data.userId = item.ext?.user?.id || '';

    // ignore certain javascript errors that are not actionable
    if (item.name === 'MS.Web.ClientError') {
        // ignore resize observer errors
        if (item.data?.message?.toString()?.includes('ResizeObserver')) {
            return false;
        }

        if (item.data?.url) {
            const url = item.data.url;
            if (typeof url === 'string' && url.includes('?st=')) {
                item.data.url = redactShareToken(url);
            }
        }
        if (item.data?.errorSrc) {
            const errorSrc = item.data.errorSrc;
            if (typeof errorSrc === 'string' && errorSrc.includes('?st=')) {
                item.data.errorSrc = redactShareToken(errorSrc);
            }
        }
    }

    if (item.baseType === 'PageActionData' || item.name === 'Ms.Web.PageAction') {
        if (item.baseData) {
            item.baseData.content = [];
        }
        if (item.data && item.data?.refUri && typeof item.data.refUri === 'string') {
            if (item.data.refUri.includes('?st=')) {
                item.data.refUri = redactShareToken(item.data.refUri);
            }
        }
    }

    if (item.baseType === 'PageviewData' || item.name === 'Ms.Web.PageView') {
        // PageView contains the refUri under data.baseData.refUri
        if (item.baseData) {
            const baseData = item.baseData;
            // Add the page name to the telemetry data
            if (typeof window !== 'undefined' && window.location && window.location.pathname) {
                baseData.name = window.location.pathname;
            }
            let refUriKey = 'refUri' as string;
            if (baseData && baseData[refUriKey]) {
                const refUri = baseData[refUriKey];
                if (typeof refUri === 'string') {
                    if (refUri.includes('?st=')) {
                        baseData[refUriKey] = redactShareToken(refUri);
                    }
                }
            }
        }
    }

    if (item.baseType === 'RemoteDependencyData' || item.name === 'MS.Web.OutgoingRequest') {
        if (item.data) {
            if (item.data.uri) {
                const uri = item.data.uri;
                if (typeof uri === 'string' && uri.includes('?st=')) {
                    item.data.uri = redactShareToken(uri);
                }
            }
        }
    }
}

export const qosConfig: IQosConfiguration = {
    autoCapture: {
        msTags: true,
    },
    enableAjaxPerfTracking: true,
    enableCorsCorrelation: true,
    enableCvHeaders: true,
    maxAjaxCallsPerView: -1,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAjaxErrorStatusText: true,
    distributedTracingMode: 2,
};

export const propertiesConfig: IPropertyConfiguration = {
    // enableApplicationInsightsTrace: true,
    enableApplicationInsightsUser: true,
    enableDistributedTracing: true,
};

const qosPlugin = new QosPlugin();

const propertiesPlugin = new PropertiesPlugin();

const config: IExtendedConfiguration = {
    instrumentationKey: MedeinaVariables.TelemetryInstrumentationKey,
    endpointUrl: ENDPOINT_URLS.Global,
    loggingLevelTelemetry: 1,
    webAnalyticsConfiguration: {
        autoCapture: {
            pageView: true,
            onLoad: true,
            onUnload: true,
            click: true,
            scroll: false,
            resize: false,
            lineage: false,
            jsError: true,
            msTags: true,
        },
        autoPopulateParentIdAndParentName: true,
        enableAutoRouteTracking: true,
    },
    extensions: enhancedTelemetry ? [qosPlugin, propertiesPlugin] : [],
    extensionConfig: enhancedTelemetry
        ? {
              qosPlugin: qosConfig,
              SystemPropertiesCollector: propertiesConfig,
          }
        : {},
};

export default config;
