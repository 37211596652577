import {LayoutWidths} from '@/components/theme/layout';
import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        //...shorthands.gap('48px'),
        ...shorthands.margin('50px', 'auto', '48px'),
        ...shorthands.padding(0),

        '@media (min-width: 1920px)': {
            width: '1464px',
        },
        '@media (min-width: 1366px) and (max-width: 1919px)': {
            width: '1086px',
        },
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            width: '905px',
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            width: '545px',
            ...shorthands.padding(0),
            ...shorthands.gap(tokens.spacingVerticalL),
            ...shorthands.margin(tokens.spacingVerticalXXL, 'auto'),
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            width: '431px',
            ...shorthands.gap(tokens.spacingVerticalL),
            ...shorthands.margin(tokens.spacingVerticalXXL, 'auto'),
        },
        '@media (max-width: 479px)': {
            width: '272px',
            ...shorthands.gap(tokens.spacingVerticalL),
            ...shorthands.margin(tokens.spacingVerticalL, 'auto'),
        },
    },
    prompt: {
        ...shorthands.flex(1, 1, 'auto'),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        '@media (max-width: 479px)': {
            marginTop: tokens.spacingVerticalXXL,
        },
        '@media (max-height: 1100px)': {
            marginBottom: tokens.spacingVerticalXXL,
        },
    },
    footer: {
        ...shorthands.flex(0, 0, 'auto'),
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('10px'),
        width: '100%',
    },
    cards: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap('10px'),
        width: '100%',
    },
    cardLink: {
        ...shorthands.flex(1, 1, 'auto'),
    },
    card: {
        backgroundColor: tokens.colorNeutralBackground3,
        minHeight: '20vh',
    },
});
