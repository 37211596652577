import {useMutation, useQueryClient} from '@tanstack/react-query';
import {GetWorkspaceByNameResponse, Workspace} from '.';
import MedeinaVariables from '@/util/variables';
import {customFetch} from '../api';
import {RESOURCE_SCOPES} from '../api.constants';
import {UpdateWorkspaceRequest, DeleteWorkspaceRequest} from './workspaces.types';

/**
 * Hook to create or update a workspace
 * if the workspace does not already exist the workspace will be created
 * This is a new version of the useCreateWorkspace hook
 * @returns GetWorkspaceByNameResponse
 */
export default function useDeleteWorkspace() {
    const queryClient = useQueryClient();

    const getUrl = (workspaceName: string) =>
        `${MedeinaVariables.FidelisSecCopUri}/account/workspaces/${workspaceName}?api-version=2023-12-01-preview`;

    return useMutation({
        mutationFn: async (request: DeleteWorkspaceRequest) => {
            try {
                return await customFetch<Response>(
                    getUrl(request.name),
                    {
                        method: 'DELETE',
                        body: request,
                        scopes: RESOURCE_SCOPES.FIDELIS,
                    },
                    false,
                );
            } catch (e) {
                throw e;
            }
        },
        onSuccess: (data, variables, context) => {},
        onSettled: (data, error, variables, context) => {
            queryClient.removeQueries(['workspaces']);
            queryClient.removeQueries(['workspaces', variables.name]);
        },
        onError: (error, variables) => {},
        retry: 0,
    });
}
