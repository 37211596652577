import React from 'react';
import {getStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';
import MedeinaFeatures from '@/util/features';
import WorkspacesContext from './WorkspacesContext';

/** Manages rendering the WorkspacesContextProvider if enabled
 * @returns the WorkspacesProvider component or children
 *
 * WIP - Only available in dev with feature flag
 */
const WorkspacesProvider = ({children}: {children: React.ReactNode}) => {
    const showWorkspacesProvider = getStaticFeatureFlag(MedeinaFeatures.WorkspacesEnabled);

    if (!showWorkspacesProvider) {
        return <>{children}</>;
    }
    console.log('WorkspacesProvider is enabled');
    return <WorkspacesContext>{children}</WorkspacesContext>;
};

export default WorkspacesProvider;
