import {KeyValueFilterDetails, SelectedSearchFacet} from '@/api/capacities/capacities.types';
import {DismissIcon} from '@/components/ui/icons';
import {
    Button,
    Menu,
    MenuList,
    MenuPopover,
    MenuTrigger,
    MenuTriggerChildProps,
    mergeClasses,
    Tooltip,
} from '@fluentui/react-components';
import useClasses from './FilterPills.styles';
import {PillMenuItems} from './PillMenuItems';
import {getDisplayValue, removeFilterByCategory, removeFilterByValue} from './Filter.utils';
import useGetSelectedFilterPillsLabels from './useGetSelectedFilterPillsLabels';
import {useTranslation} from 'react-i18next';
import {SelectedFilterPillAriaLabelsPrefix} from '../UsageDashboard.constants';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';
import {FilterMenuItem} from './FilterMenuItem';

interface FilterPillsProps {
    filters: SelectedSearchFacet[];
    onFiltersUpdated: (filters: SelectedSearchFacet[]) => void;
    copilotExperienceMap: KeyValueFilterDetails[];
}

export default function FilterPills(props: FilterPillsProps) {
    const classes = useClasses();
    const scrollClasses = useScrollClasses();

    const {filters, copilotExperienceMap} = props;
    const {filterPillsNumber, multiSelectedLabels, singleSelectedLabels, getMoreFiltersLabel} =
        useGetSelectedFilterPillsLabels({filters, copilotExperienceMap});

    const {t} = useTranslation('admin');

    return (
        <>
            {props.filters.slice(0, filterPillsNumber).map((filter, index) => (
                <Menu key={index}>
                    <MenuTrigger disableButtonEnhancement>
                        {(prop: MenuTriggerChildProps<'button'>) => (
                            <div className={classes.dropdownTriggerBtn}>
                                <Button
                                    {...prop}
                                    appearance="transparent"
                                    className={classes.menuButton}
                                    aria-label={
                                        filter.values.length == 1
                                            ? singleSelectedLabels[filter.category]
                                            : multiSelectedLabels[filter.category]
                                    }
                                >
                                    <div className={classes.ellipsisText}>
                                        {filter.values.length == 1 ? (
                                            <Tooltip
                                                content={getDisplayValue(
                                                    filter,
                                                    props.copilotExperienceMap,
                                                )}
                                                relationship="label"
                                            >
                                                <span>{singleSelectedLabels[filter.category]}</span>
                                            </Tooltip>
                                        ) : (
                                            <>{multiSelectedLabels[filter.category]}</>
                                        )}
                                    </div>
                                </Button>
                                <Button
                                    appearance="transparent"
                                    icon={<DismissIcon />}
                                    onClick={() => {
                                        const updatedFilters = removeFilterByCategory(
                                            filter.category,
                                            props.filters,
                                        );
                                        props.onFiltersUpdated(updatedFilters);
                                    }}
                                    className={classes.dismissButton}
                                    aria-label={t(
                                        `${SelectedFilterPillAriaLabelsPrefix}.RemoveFilter`,
                                    )}
                                />
                            </div>
                        )}
                    </MenuTrigger>

                    <PillMenuItems
                        filter={filter}
                        onFilterRemoval={(category, value) => {
                            {
                                const updatedFilters = removeFilterByValue(
                                    category,
                                    value,
                                    props.filters,
                                );
                                props.onFiltersUpdated(updatedFilters);
                            }
                        }}
                        index={index}
                        copilotExperienceMap={props.copilotExperienceMap}
                    ></PillMenuItems>
                </Menu>
            ))}

            {props.filters.length > filterPillsNumber && (
                <Menu key="moreFilters">
                    <MenuTrigger disableButtonEnhancement>
                        <Button className={classes.moreFiltersButton} appearance="transparent">
                            {getMoreFiltersLabel(props.filters.length - filterPillsNumber)}
                        </Button>
                    </MenuTrigger>

                    <MenuPopover>
                        <MenuList
                            className={mergeClasses(
                                scrollClasses.colorNeutralBackground1,
                                classes.listOverflow,
                            )}
                        >
                            {props.filters.slice(filterPillsNumber).map((filter, index) => (
                                <FilterMenuItem
                                    key={index}
                                    filter={filter}
                                    index={index}
                                    filterPillsProps={props}
                                />
                            ))}
                        </MenuList>
                    </MenuPopover>
                </Menu>
            )}
        </>
    );
}
