import {ChangeEvent, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    Link,
    MessageBar,
    Toolbar,
    ToolbarButton,
    MessageBarActions,
    MessageBarBody,
    Button,
    mergeClasses,
} from '@fluentui/react-components';
import PromptBarMenu from './PromptBarMenu';
import ConnectorSourcesDialog from '../ConnectorModal/ConnectorSourcesModal';
import useClasses from './PromptBar.styles';
import {PromptBarProps, PromptBarSubmitOptions, ScreenName} from './PromptBar.types';
import {AcceptIcon, DismissIcon, HelpIcon, SendIcon} from '@/components/ui/icons';
import {SkillDescriptor, useGetAllSkills} from '@/api/skills';
import PromptBarSkillForm from './PromptBarSkillForm';
import PromptBarInput from './PromptBarInput';
import {PromptCategory, PromptInputs, PromptType} from '@/api/prompts';
import {useTourable} from '@/components/ui/Tour/useTourable';
import {PromptbookDescriptor, PromptbookInputs, PromptbookPrompts} from '@/api/promptbooks';
import PromptBarPromptbookForm from './PromptBarPromptbookForm';
import {useLayout} from '../Layout';
import {useLocation, useNavigate} from 'react-router-dom';
import usePromptbarSubmit from './usePromptbarSubmit';
import ToastNotification from '@/components/ui/Toasts/ToastNotification';
import {useErrorMessages} from '@/api/errors';
import {useTranslation} from 'react-i18next';
import {getCachedUrlParamFlags} from '@/util/hasStaticFeatureFlags';
import {MenuType} from '@/components/HeaderMenu.types';
import {ErrorMessage} from '@/api/errors.types';
import MedeinaFeatures from '@/util/features';

export * from './PromptBar.types';
/**
 * A component that renders a prompt bar with a text input and optional skill selection.
 *
 * Usage:
 * Starting a new investigation
 * <PromptBar enableSkillsetConnector="true" />
 *
 * Editing an existing prompt
 * <PromptBar editMode="true" enableSkillsetConnector="false" defaultPrompt="Some prompt that had already been submitted" />
 */
export default function PromptBar({
    onSubmit,
    onCancel = () => null,
    // TODO: Remove this when promptbook modal is decoupled from the prompt bar
    onPromptbookCancel = () => null,
    className,
    disabled = false,
    // Enables the Skillset Connector modal
    enableSkillsetConnector = false,
    // Enables the Support Assistance modal
    enableSupportAssistance = false,
    // Enables edit mode styling, used when editing an existing prompt
    editMode = false,
    // For existing prompts this value sets the default value
    defaultPrompt = undefined,
    defaultValue = undefined,
    defaultSkill = undefined,
    defaultSkillsets = undefined,
    defaultPromptbook = undefined,
    defaultPromptbookPrompts = undefined,
    submitDefaultValue = false,
    fluid = false,
    autoFocus = false,
    screenName = ScreenName.Session,
    restoreFocusSourceAttribute,
}: PromptBarProps) {
    const restoreFocusAttribute = restoreFocusSourceAttribute ?? {};
    const tourRef = useTourable({id: 'promptBar'});
    const classes = useClasses();
    const {t} = useTranslation('promptbar');
    const {getErrorMessage} = useErrorMessages();
    const {sidePanel} = useLayout();
    const {pathname} = useLocation();
    const isHome = pathname === '/';
    const isNewSessionPage = pathname === '/sessions/new';
    const [showSkillsetConnector, setShowSkillsetConnector] = useState(false);

    // Depending where the promptbar is rendered we may want to max it fluid or fixed.
    const rootClasses = editMode
        ? mergeClasses(classes.root, classes.rootResponsive)
        : classes.root;
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const inputWrapperRef = useRef<HTMLDivElement>(null);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [promptbookFormOpen, setpromptbookFormOpen] = useState<boolean>(false);
    const [errMessage, setErrMessage] = useState<ErrorMessage | undefined>(undefined);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(disabled);

    // Handle skill states.
    const [skill, setSkill] = useState<SkillDescriptor | null>(defaultSkill ?? null);
    const [skillInputs, setSkillInputs] = useState<PromptInputs>(defaultPrompt?.inputs ?? {});
    const [skillsets, setSkillsets] = useState<string[] | null>(null);

    // Handle promptbook states.
    const [promptbook, setPromptbook] = useState<PromptbookDescriptor | null>(
        defaultPromptbook ?? null,
    );
    const [promptbookInputs, setPromptbookInputs] = useState<PromptbookInputs>(
        defaultPrompt?.inputs ?? {},
    );
    const [promptbookPrompts, setPromptbookPrompts] = useState<PromptbookPrompts[] | null>(
        defaultPromptbookPrompts ?? null,
    );

    // User specified featureFlags and skillsets will overwrite default selections.
    const userFeatureFlags = useMemo<string[] | undefined>(() => {
        const flags = getCachedUrlParamFlags(false);
        return flags.length > 0 ? flags : undefined;
    }, []);
    const userSkillsets = useMemo<string[] | undefined>(() => {
        const params = new URLSearchParams(window.location.search);
        return (
            params
                .get('skillsets')
                ?.split(',')
                .map((item: string) => item.trim()) ?? undefined
        );
    }, [typeof window !== 'undefined' && window.location.search]);

    // We're using a controlled value for the textarea.
    // This is because there are many interactions that require fine-tuned control.
    const [value, setValue] = useState<string>(defaultPrompt?.content ?? defaultValue ?? '');
    const navigate = useNavigate();

    const requiredInputFieldsForSkill: Array<string> = useMemo(() => {
        const requiredFields = new Array<string>();
        if (skill && skill.inputs) {
            for (const {required, name} of Object.values(skill.inputs)) {
                if (required) {
                    requiredFields.push(name);
                }
            }
        }
        return requiredFields;
    }, [skill]);

    // Handle new default values passed from outside the PromptBar.
    useEffect(() => {
        if (textareaRef.current) {
            // Determine the next value and pre-set it, so that we can set the selection range reliably.
            const nextValue = defaultPrompt?.content ?? defaultValue ?? textareaRef.current.value;
            textareaRef.current.value = nextValue;
            setSkill(null);
            setSkillInputs({});
            setValue(nextValue);

            // If the new value has a bracketed string, pre-select that text for manual replacement.
            if (/\{.+?\}/.test(nextValue)) {
                textareaRef.current.setSelectionRange(
                    nextValue.indexOf('{{'),
                    nextValue.indexOf('}}') + 2,
                );
            }
            textareaRef.current.focus();
        }
    }, [defaultPrompt?.content, defaultValue, textareaRef]);
    // Handle new default skills from prompt suggestions passed from outside the PromptBar.
    useEffect(() => {
        setSkill(defaultSkill ?? null);
        setSkillInputs({});
    }, [defaultSkill]);

    // Default implementation for submit
    const {
        errorCreateSessionWithPrompt,
        isErrorCreateSessionWithPrompt,
        resetCreateSessionWithPrompt,
        onSubmit: onDefaultSubmit,
        promptbookApplySuccess,
        promptbookLoading,
        errorCreateSessionWithPromptbook,
    } = usePromptbarSubmit();

    // Submit the prompt.
    const handleSubmit = useCallback(() => {
        let options: PromptBarSubmitOptions | null = null;
        const content = value?.trim();
        setValue('');

        // Skills with inputs.
        if (skill) {
            for (const requiredInput of requiredInputFieldsForSkill) {
                if (!skillInputs[requiredInput]) {
                    return;
                }
            }

            options = {
                promptType: PromptType.Skill,
                skillName: skill.name,
                inputs: skillInputs,
                promptCategory: PromptCategory.UserGenerated,
            };
        }
        // Normal prompts.
        else if (content) {
            options = {
                promptType: PromptType.Prompt,
                content,
            };
        }
        // Prompt book.
        else if (promptbook) {
            options = {
                promptType: PromptType.Prompt,
                inputs: promptbookInputs,
                content: promptbook.name,
                promptbookId: promptbook.promptbookId,
                promptbookPrompts: promptbookPrompts,
            };
        }

        // Only continue with a valid options object.
        if (options && content !== '/' && content !== '*') {
            options.skillsets = userSkillsets ?? defaultSkillsets ?? skillsets ?? undefined;
            options.featureFlags = userFeatureFlags ?? undefined;

            // If onSubmit was overriden then use that
            if (onSubmit) {
                onSubmit?.(options);
            } else {
                onDefaultSubmit?.(options);
            }
        }
    }, [
        onSubmit,
        skill,
        skillsets,
        value,
        skillInputs,
        promptbook,
        promptbookInputs,
        promptbookPrompts,
    ]);

    // Automatically submit if requested (headless mode).
    useEffect(() => {
        if (defaultValue && submitDefaultValue) {
            handleSubmit();
        }
    }, [defaultValue, submitDefaultValue]);

    useEffect(() => {
        for (const requiredInput of requiredInputFieldsForSkill) {
            if (!skillInputs[requiredInput]) {
                setIsSubmitDisabled(true);
                return;
            }
        }
        setIsSubmitDisabled(false);
    }, [requiredInputFieldsForSkill, skillInputs]);

    // Handle menu triggers.
    const handleChange = useCallback(
        (ev: ChangeEvent<HTMLTextAreaElement>) => {
            // Always persist the value, as we're using a controlled textarea.
            let value = ev.target.value;
            setValue(value);
        },
        [setValue],
    );

    // Handle skill selection.
    const handleSelect = useCallback(
        (skill: SkillDescriptor | null) => {
            setSkill(skill);
            setSkillInputs({});
            setMenuOpen(false);
            setValue('');
            textareaRef.current?.focus();
        },
        [textareaRef, setSkill, setMenuOpen, setValue],
    );

    // Handle promptbook selection.
    const handlePromptbookSelect = useCallback(
        (promptbook: PromptbookDescriptor | null) => {
            setMenuOpen(false);
            // if cancelled trigger cancel callback
            if (promptbook === null) {
                onPromptbookCancel();
            }
            setPromptbook(promptbook);
            setPromptbookInputs({});
            setValue('');

            textareaRef.current?.focus();
            if (promptbook !== null) {
                setpromptbookFormOpen(true);
                if (isHome) {
                    navigate(`/sessions/new/${promptbook?.promptbookId}`);
                }
            } else {
                setpromptbookFormOpen(false);
            }
        },
        [textareaRef, setPromptbook, setValue],
    );
    useEffect(() => {
        if (defaultPromptbook) {
            handlePromptbookSelect(defaultPromptbook);
        }
    }, [defaultPromptbook]);

    // Handle key up events.
    const handleKeyUp = useCallback(
        (ev: KeyboardEvent<HTMLTextAreaElement>) => {
            const value = textareaRef.current?.value.trim();

            // Clear selected skill when backspace is pressed with an empty prompt.
            if (value?.length === 0 && ev.key === 'Backspace') {
                handleSelect(null);
            }

            // When editing a prompt, escape can be used to cancel.
            if (ev.key === 'Escape') {
                if (menuOpen) {
                    setMenuOpen(false);
                }
                onCancel?.();
            }
        },
        [textareaRef, handleSelect, onCancel],
    );

    // If the user is editing a skill-based prompt, we need access to the list of skills to pull up the descriptor.
    const {data: allSkills} = useGetAllSkills();
    useEffect(() => {
        if (
            defaultPrompt?.promptType &&
            [PromptType.Skill, PromptType.SkillDirect].includes(defaultPrompt.promptType) &&
            allSkills?.value
        ) {
            setSkill(
                allSkills.value.find((skill) => skill.name === defaultPrompt.skillName) ?? null,
            );
            setSkillInputs(defaultPrompt?.inputs ?? {});
        }
    }, [defaultPrompt, allSkills?.value, handleSelect]);

    const menuOpenedWithShortcut = useMemo(() => value?.startsWith('/'), [value]);
    const menuPromptbookWithShortcut = useMemo(() => value?.startsWith('*'), [value]);

    // Focus the promptbar on load.
    // This also runs on menu toggle, as otherwise, focus is not maintained.
    useEffect(() => {
        if (textareaRef.current) {
            // only trigger focus if the menu is closed, or if it's open and was opened with a shortcut
            if (!menuOpen || menuOpenedWithShortcut || menuPromptbookWithShortcut) {
                textareaRef.current.focus();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textareaRef, textareaRef.current, menuOpen, skill]);

    useEffect(() => {
        if (isErrorCreateSessionWithPrompt) {
            var message = getErrorMessage((errorCreateSessionWithPrompt as any).response.status);
            setErrMessage(message);
        }
    }, [isErrorCreateSessionWithPrompt]);

    return (
        <>
            <div
                className={mergeClasses(
                    screenName === ScreenName.Session && classes.rootSession,
                    screenName === ScreenName.Home && classes.rootHome,
                    (fluid || editMode) && classes.rootResponsive,
                    className,
                )}
                ref={tourRef}
                data-testid="promptbar-root"
            >
                {!promptbook && (
                    <form className={classes.form} onSubmit={handleSubmit}>
                        {skill ? (
                            <div className={classes.skillFormWrapper}>
                                <PromptBarSkillForm
                                    skill={skill}
                                    skillInputs={skillInputs}
                                    onCancel={() => handleSelect(null)}
                                    onChange={(inputs) => setSkillInputs(inputs)}
                                    onSubmit={handleSubmit}
                                />
                                {(editMode || !defaultPrompt) && (
                                    <Toolbar className={classes.toolbar}>
                                        {defaultPrompt ? (
                                            <>
                                                <ToolbarButton
                                                    aria-label={t('DismissChangesButton')}
                                                    appearance="subtle"
                                                    icon={<DismissIcon />}
                                                    onClick={() => {
                                                        onCancel?.();
                                                    }}
                                                    disabled={disabled}
                                                    {...restoreFocusAttribute}
                                                />
                                                <ToolbarButton
                                                    aria-label={t('AcceptChangesButton')}
                                                    appearance="subtle"
                                                    icon={<AcceptIcon />}
                                                    onClick={handleSubmit}
                                                    disabled={disabled}
                                                    {...restoreFocusAttribute}
                                                />
                                            </>
                                        ) : (
                                            <ToolbarButton
                                                aria-label={t('StartSessionButton')}
                                                appearance="subtle"
                                                icon={<SendIcon />}
                                                onClick={handleSubmit}
                                                disabled={isSubmitDisabled}
                                            />
                                        )}
                                    </Toolbar>
                                )}
                            </div>
                        ) : (
                            <PromptBarInput
                                inputWrapperRef={inputWrapperRef}
                                textareaRef={textareaRef}
                                disabled={disabled}
                                value={value}
                                handleChange={handleChange}
                                handleKeyUp={handleKeyUp}
                                handleSubmit={handleSubmit}
                                handleSkillMenuToggle={() => setMenuOpen(!menuOpen)}
                                handleShowSkillsetConnector={() => setShowSkillsetConnector(true)}
                                enableSkillsetConnector={enableSkillsetConnector}
                                enableSupportAssistance={enableSupportAssistance}
                                editMode={editMode}
                                autofocus={autoFocus}
                                screenName={screenName}
                            />
                        )}
                    </form>
                )}
                {promptbook != null && !isHome && (
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <div className={classes.skillFormWrapper}>
                            <PromptBarPromptbookForm
                                promptbook={promptbook}
                                promptbookInputs={promptbookInputs}
                                promptbookPrompts={promptbookPrompts}
                                onCancel={() => handlePromptbookSelect(null)}
                                onChange={(inputs) => setPromptbookInputs(inputs)}
                                onPromptsChange={(prompts) => setPromptbookPrompts(prompts)}
                                onSubmit={handleSubmit}
                                open={promptbookFormOpen}
                                errorCreateSessionWithPromptbook={errorCreateSessionWithPromptbook}
                            />
                        </div>
                    </form>
                )}
                <PromptBarMenu
                    ref={inputWrapperRef}
                    open={menuOpen}
                    skillsets={userSkillsets ?? defaultSkillsets ?? skillsets ?? undefined}
                    onSkillSelect={handleSelect}
                    onPromptbookSelect={handlePromptbookSelect}
                    onHide={() => setMenuOpen(false)}
                />
                {!enableSkillsetConnector && defaultPrompt && !skill && (
                    <div className={classes.actionsToolbar}>
                        <Toolbar className={classes.toolbar}>
                            <ToolbarButton
                                aria-label={t('DismissChangesButton')}
                                data-testid="dismiss-changes-button"
                                appearance="subtle"
                                icon={<DismissIcon />}
                                onClick={() => {
                                    onCancel?.();
                                }}
                                disabled={disabled}
                                {...restoreFocusAttribute}
                            />
                            <ToolbarButton
                                aria-label={t('AcceptChangesButton')}
                                data-testid="accept-changes-button"
                                appearance="subtle"
                                icon={<AcceptIcon />}
                                onClick={handleSubmit}
                                disabled={disabled}
                                {...restoreFocusAttribute}
                            />
                        </Toolbar>
                    </div>
                )}
                {enableSkillsetConnector && (
                    <div className={classes.connectorModal}>
                        <ConnectorSourcesDialog
                            open={showSkillsetConnector}
                            menuType={MenuType.PromptBar}
                            defaultSelectedSkillsets={userSkillsets}
                            onChangeSkillsets={(skillsets: string[]) => setSkillsets(skillsets)}
                            onClose={() => setShowSkillsetConnector(false)}
                        />
                    </div>
                )}
            </div>
            {isErrorCreateSessionWithPrompt && (
                <MessageBar intent="error" politeness="assertive">
                    <MessageBarBody>
                        {errMessage?.message}
                        {errMessage?.learnMoreText && errMessage?.learnMoreUri && (
                            <>
                                {' '}
                                <Link href={errMessage.learnMoreUri}>
                                    {errMessage.learnMoreText}
                                </Link>
                            </>
                        )}
                    </MessageBarBody>
                    <MessageBarActions
                        containerAction={
                            <Button
                                onClick={resetCreateSessionWithPrompt}
                                aria-label={t('DismissButton')}
                                appearance="transparent"
                                icon={<DismissIcon />}
                                {...restoreFocusAttribute}
                            />
                        }
                    />
                </MessageBar>
            )}
            {promptbookLoading && (
                <ToastNotification
                    intent="progress"
                    message={t('StartingPromptbook')}
                    sessionId={''}
                    timeout={10000}
                ></ToastNotification>
            )}
            {promptbookApplySuccess && (
                <ToastNotification
                    intent="success"
                    message={t('PromptbookStarted')}
                    sessionId={''}
                    timeout={10000}
                ></ToastNotification>
            )}
        </>
    );
}
