export const MSW_NAMESPACE = 'medeina_msw';

// These map to the scenario handlers in the scenarioHandlers directory
export const MedeinaScenarios = ['helloWorld', 'multiWorkspaces', 'default', 'FRESetupForm'];

export const ScenarioFeatureFlagMap: Record<string, string> = {
    // workspaces is the only feature that has a scenario associated with it
    WorkspacesEnabled: MedeinaScenarios[1],

    // hello world is an api demo
    HelloWorld: MedeinaScenarios[0],

    FRESetupForm: MedeinaScenarios[3],
};
